import { computed, reactive, readonly, ref, } from 'vue';

import { Api } from '@/services/api.service';
import { Analytics } from '@/services/analytics.service';
import { Tracking } from '@/services/tracking.service';

const state = reactive({
  product: ref(null),
  profile: ref(null),
  coupon: ref(null),
  customer: ref(null),
  token: ref(null),
  membership: ref(null),
  subscription: ref(null),
  recipient: ref(null),
  isLoading: ref(false),
  isCreating: ref(false),
  isUpdating: ref(false),
  creatingProfile: ref(true),
  validProfile: ref(false),
  optin: ref(false),
  shipping_required: ref(false),
  shipping_address: ref({
    'shipping_address': null,
    'shipping_address_2': null,
    'shipping_city': null,
    'shipping_state': null,
    'shipping_zip': null,
    'shipping_country': null,
    'first_name': null,
    'last_name': null,
  }),
  additional_data: ref(null),
});

const loadConfig = async (config) => {
  const url = `${config.api.uri}/${config.api.version}/checkout?now=${Date.now()}`;

  return await Api.fetch(url);
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Sets the subscription product state as the provided string.
 * @param { string } product - The product handle string.
 * @returns { void }
 */
const set = (key, value) => {
  state[key] = value;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription product state as the provided string.
 * @param { string } product - The product handle string.
 * @param { Object } options - The available product options.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const updateProduct = async (product, options, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateProduct():submitted data',
      { product, options, config });
  }
  const previous = state.product;

  await set('product', product);

  const response = await updatePreview(config, options);

  if ('error' === response.status) {
    if (previous) {
      await set('product', previous);
    }
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription coupon state as the provided string.
 * @param { string } coupon - The promotion coupon string.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const updateCoupon = async (coupon, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateProduct():submitted data', { coupon, config });
  }
  await set('coupon', coupon);

  const response = await updatePreview(config);

  if ('error' === response.status) {
    await set('coupon', null);
    updateCoupon(null, config);
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the gift recipient state as the provided string.
 * @param { string } recipient - The promotion recipient string.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const updateRecipient = async (recipient, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateProduct():submitted data', { recipient, config });
  }
  await set('recipient', recipient);

  const response = await updatePreview(config);

  if ('error' === response.status) {
    await set('recipient', null);
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription payment profile state as the provided string.
 * @param { string } customer - The customer id.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const updateCustomer = async (customer, token, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateCustomer():submitted data',
      { customer, token, config });
  }
  const previous = state.customer;

  await set('customer', customer);
  await set('token', token);

  const response = await updatePreview(config);

  if ('error' === response.status) {
    // await set('product', previous);
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription state as the provided string.
 * @param { string } subscription - The subscription id.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const updateMembership = async (membership, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateMembership():submitted data', { membership, config });
  }
  const previous = state.membership;

  await set('membership', membership);

  const response = await updatePreview(config);

  if ('error' === response.status) {
    // await set('membership', previous);
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription payment profile state as the provided string.
 * @param { string } profile - The profile profile id.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const activateProfile = async (profile, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:activateProfile():submitted data', { profile, config });
  }
  const previous = state.profile;

  await set('profile', profile);

  const response = await updatePreview(config);

  if ('error' === response.status) {
    await set('profile', previous);
    return response;
  }
  set('preview', response.data.data);
  return response;
};

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription payment profile state as the provided string.
 * @param { string } membership - The customers membership subscription id.
 * @param { string } profile - The profile profile id.
 * @param { Config } config - The component configuration.
 * @returns { Response }
 */
const setProfile = async (membership, profile, config) => {
  const previous = state.profile;
  const { customer, token } = state;
  if (config.debug) {
    console.log('Providers.Subscription:setProfile():submitted data', { profile, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Subscription:setProfile() | Api config is required to retreive customer information.');
  }
  if (!membership) {
    throw Error('Providers.Subscription:setProfile() | Membership Subscription id is required.');
  }
  if (!profile) {
    throw Error('Providers.Subscription:setProfile() | Payment Profile id is required.');
  }
  if (!token) {
    throw Error('Providers.Subscription:setProfile() | Token is required.');
  }

  await set('profile', profile);

  const url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/${membership}?token=${token}&now=${Date.now()}`;

  state.isLoading = true;
  const body = {
    payment_profile_id: profile
  };

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.patch(url, body, headers);

  if ('error' === response.status) {
    await set('profile', previous);
    return response;
  }

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Subscription:setProfile():response data', response);
  }
  return response;
};

const removeProfile = async (membership, profile, config) => {
  const previous = state.profile;
  const { customer, token } = state;
  if (config.debug) {
    console.log('Providers.Subscription:removeProfile():submitted data', { profile, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Subscription:removeProfile() | Api config is required to retreive customer information.');
  }
  if (!membership) {
    throw Error('Providers.Subscription:removeProfile() | Membership Subscription id is required.');
  }
  if (!profile) {
    throw Error('Providers.Subscription:removeProfile() | Payment Profile id is required.');
  }
  if (!token) {
    throw Error('Providers.Subscription:removeProfile() | Token is required.');
  }

  const url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/${membership}/profiles/${profile}?token=${token}&now=${Date.now()}`;

  state.isLoading = true;

  const response = await Api.remove(url);

  if ('error' === response.status) {
    await set('profile', previous);
    return response;
  }

  await set('profile', null);

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Subscription:removeProfile():response data', response);
  }
  return response;
};

const memberships = [
  'korm-plus-monthly',
  'konrm-plus-monthly',
  'korm-plus-annual',
  'konrm-plus-annual',
  'korm-pro-monthly',
  'konrm-pro-monthly',
  'korm-pro-monthly-2mft-suf',
  'korm-pro-monthly-11dft',
  'korm-pro-monthly-2mft',
  'korm-pro-monthly-1mft',
  'konrm-pro-annual-99',
  'korm-pro-annual-napp',
  'konrm-pro-annual-napp',
  'konrm-pro-annual-129',
  'konrm-pro-annual-139',
  'korm-pro-annual-149',
  'konrm-pro-annual-159',
  'korm-pro-annual-169',
  'konrm-pro-annual-169',
  'korm-pro-annual-179',
  'konrm-pro-annual-179',
  'konrm-pro-annual-189',
  'korm-pro-annual-1mt',
  'kom-henry-full-access',
  'korm-pro-annual',
  'korm-pro-annual-11dft',
  'konrm-pro-annual',
  'konrm-pro-annual-319',
  'korm-pro-annual-159',
  'konrm-pro-6month',
  'korm-pro-monthly-dpb-us',
  'korm-pro-monthly-dpb-intl',
  'korm-vip-monthly',
  'korm-vip-annual',
  'korm-pro-annual-sales',
  'korm-pro-monthly-iphone-intl',
  'korm-pro-monthly-iphone-us'
];

/**
 * @memberof Providers.Subscription
 * @instance
 * @summary Updates the subscription preview state as the provided string.
 * @returns { Response }
 */
const updatePreview = async (config = {}, options = {}) => {
  const { product, profile, coupon, customer, token, membership, recipient } = state;
  if (config.debug) {
    console.log('Providers.Subscription:updatePreview():submitted data',
      { product, options, profile, coupon, customer, token, membership, config, recipient });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Auth:loadUser() | Api config is required to retreive customer information.');
  }
  let url = `${config.api.uri}/${config.api.version}/customers/subscriptions/preview`;

  if (customer) {
    if (!token) {
      throw Error(
        'Providers.Subscription:updatePreview() | User Token is required to retreive customer information.');
    }
    const exists = memberships.filter((handle) => handle === product);

    if (membership && 'konrm-pro-annual-gift' !== product && exists.length) {
      url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/${membership}/preview?token=${token}&now=${Date.now()}`;
    } else {
      url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/preview?token=${token}&now=${Date.now()}`;
    }
  }

  state.isLoading = true;
  const body = {};

  if (product) {
    body.product_handle = product;
  }
  // if (options) body.available_products = options;
  if (profile) {
    body.payment_profile_id = profile;
  }
  if (coupon) {
    body.coupon_code = coupon;
  }
  if (recipient) {
    body.gift_email = recipient;
  }
  if (state.additional_data) {
    body.additional_data = state.additional_data;
  }

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  if (Object.keys(body).length === 0) {
    return { status: false, data: false };
  }

  const response = await Api.post(url, body, headers);
  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Subscription:updatePreview():response data', response);
  }

  return response;
};

const purchase = async (config = {}) => {
  const { product, profile, coupon, customer, token, membership, recipient, optin, shipping_required } = state;
  if (config.debug) {
    console.log('Providers.Subscription:purchase():submitted data',
      { product, profile, coupon, customer, token, membership, recipient, config });
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Subscription:purchase() | Api config is required to retreive customer information.');
  }
  let url = `${config.api.uri}/${config.api.version}/customers/subscriptions`;

  if (customer) {
    if (!token) {
      throw Error(
        'Providers.Subscription:updatePreview() | User Token is required to retreive customer information.');
    }
    const exists = memberships.filter((handle) => handle === product);

    if (membership && 'konrm-pro-annual-gift' !== product && exists.length) {
      url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/${membership}?token=${token}&now=${Date.now()}`;
    } else {
      url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions?token=${token}&now=${Date.now()}`;
    }
  }

  state.isLoading = true;
  const body = {};

  let name = `affiliate=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let parts = decodedCookie.split(';');

  let affiliate = '';

  for (let part of parts) {
    if (part.charAt(0) == ' ') {
      part = part.substring(1);
    }

    if (part.startsWith(name)) {
      affiliate = part.substring(name.length, part.length);
    }
  }

  if (product) {
    body.product_handle = product;
  }
  // if (options) body.available_products = options;
  if (profile) {
    body.payment_profile_id = profile;
  }
  if (coupon) {
    body.coupon_code = coupon;
  }
  if (recipient) {
    body.gift_email = recipient;
  }
  if ('' !== affiliate) {
    body.affiliate = { code: affiliate };
  }
  if (optin) {
    body.optin_consent = true;
  }
  if (shipping_required) {
    const { shipping_address, shipping_address_2, shipping_city, shipping_state, shipping_zip, shipping_country, first_name, last_name } = state.shipping_address;
    if (!state.shipping_address.shipping_address || !state.shipping_address.shipping_city || !state.shipping_address.shipping_state || !state.shipping_address.shipping_zip || !state.shipping_address.shipping_country) {
      throw Error(
        'Providers.Subscription:purchase() | A Complete shipping address is required.');
    }
    body.shipping_address = shipping_address;
    body.shipping_address_2 = shipping_address_2;
    body.shipping_city = shipping_city;
    body.shipping_state = shipping_state;
    body.shipping_zip = shipping_zip;
    body.shipping_country = shipping_country;
    body.first_name = first_name;
    body.last_name = last_name;
  }
  if (state.additional_data) {
    body.additional_data = state.additional_data;
  }

  Tracking.track(`koc-purchase-submitted-${customer}`,
    { url, body, customer, timestamp: Date.now() });

  if (Object.keys(body).length === 0) {
    return;
  }

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.post(url, body, headers);

  Tracking.track(`koc-purchase-response-${customer}`,
    { url, response: response.data.data, customer, timestamp: Date.now() });

  state.isLoading = false;

  if (config.debug) {
    console.log('Providers.Subscription:purchase():response data', response);
  }

  if (response.status === 'success') {
    if (config.analytics) {
      const purchase = response.data.data.previous;

      if (purchase) {
        if (config.analytics.fbq) {
          const tracking = { currency: 'USD', value: (purchase.payment.total / 100).toFixed(2) };
          await Analytics.track('fbq', 'Purchase', tracking, config);
          await Tracking.track(`koc-purchase-fbq-${customer}`,
            { tracking, customer, timestamp: Date.now() });
        }
        if (config.analytics.gtm) {
          let name = `affiliate=`;
          let decodedCookie = decodeURIComponent(document.cookie);
          let parts = decodedCookie.split(';');

          let affiliate = '';

          for (let part of parts) {
            if (part.charAt(0) == ' ') {
              part = part.substring(1);
            }

            if (part.startsWith(name)) {
              affiliate = part.substring(name.length, part.length);
            }
          }

          const tracking = {
            event: 'purchase',
            transactionId: response.data.data.id,
            transactionDate: purchase.dates.start,
            transactionType: 'sale',
            transactionAffiliation: affiliate,
            transactionTotal: purchase.payment.total / 100,
            transactionShipping: 0,
            transactionTax: purchase.payment.taxes / 100,
            transactionPaymentType: purchase.profile.type,
            transactionCurrency: 'USD',
            transactionShippingMethod: '',
            transactionPromoCode: purchase.coupon.code,
            transactionProducts: [{
              sku: purchase.product.handle,
              name: purchase.product.name,
              category: purchase.product.id,
              price: purchase.payment.product / 100,
              quantity: 1
            }],
          };
          await Analytics.track('gtm', 'purchase', tracking, config);
          await Tracking.track(`koc-purchase-gtm-${customer}`,
            { tracking, customer, timestamp: Date.now() });
        }
        if (config.analytics.ga) {
          const tracking = {
            hitType: 'event',
            eventAction: `purchase`,
            eventLabel: purchase.product.handle
          };
          await Analytics.track('ga', 'purchase', tracking, config);
          await Tracking.track(`koc-purchase-ga-${customer}`,
            { tracking, customer, timestamp: Date.now() });
        }
      }
    }
    setTimeout(async () => {
      if (response.data.data.redirect && response.data.data.redirect.url) {
        await Tracking.track(`koc-purchase-redirect-${customer}`,
          { redirect: response.data.data.redirect.url, timestamp: Date.now() });
        window.location.replace(response.data.data.redirect.url);

        return response;
      }
    }, 0);
  }

  return response;
};

const loadUserSubscription = async (user, customer, subscription, config = {}) => {
  // Add debug check for console.log of submitted data
  if (config.debug) {
    console.log('Providers.Subscription:loadSubscription():submitted data',
      { user, customer, subscription });
  }

  // Check for required fields and throw error as required
  if (!subscription) {
    throw Error(
      'Providers.Subscription:loadSubscription() | Subscription ID is required to retreive user subscription data.');
  }
  if (!customer) {
    throw Error(
      'Providers.Subscription:loadSubscription() | Customer ID is required to retreive user subscription data.');
  }
  if (!user.token) {
    throw Error(
      'Providers.Subscription:loadSubscription() | User Token is required to retreive user subscription information.');
  }
  if (!config.api.uri) {
    throw Error(
      'Providers.Auth:loadSubscription() | Api config is required to retreive user subscription information.');
  }
  let url = `${config.api.uri}/${config.api.version}/customers/${customer}/subscriptions/${subscription}?token=${user.token}&now=${Date.now()}`;

  // Add API GET call to subscription URL
  state.isLoading = true;

  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');

  const response = await Api.get(url, headers);

  state.isLoading = false;

  // Add debug check for console.log of response data
  if (config.debug) {
    console.log('Providers.Subscription:loadSubscription():response data', response);
  }

  // Set local state data on successful response
  if (response.status !== 'error') {
    set('subscription', response.data.data);
  }

  return response;
};

const updateOptin = async (boolean, config) => {
  if (config.debug) {
    console.log('Providers.Subscription:updateOptin():submitted data', { boolean });
  }

  await set('optin', Boolean(boolean));

  return state.optin;
};

const updateShipping = async (address) => {
  state.shipping_address = address;

  return state.shipping_address;
};
const setShippingRequired = (value) => {
  state.shipping_required = Boolean(value);
};

const setAdditionalData = (data) => {
  state.additional_data = data;
};

/**
 * KelbyOne Subscription Provider
 * @namespace Providers.Subscription
 * @ignore
 * @summary KelbyOne Subscription Provider
 * @property { Boolean } isLoading General loading state.
 * @property { Boolean } isCreating General creating state.
 * @property { Boolean } isUpdating General updating state.
 * @property { String | null } getProduct Returns the state of the subscription product.
 * @property { Boolean } hasProduct Returns whether a subscription product exists.
 * @property { String | null } getProfile Returns the state of the subscription payment profile.
 * @property { Boolean } hasProfile Returns whether a subscription payment profile exists.
 * @property { String } getCoupon Returns the state of the subscription coupon.
 * @property { Boolean } hasCoupon Returns whether a subscription coupon exists.
 * @property { Object | null } getPreview Returns the state of the subscription preview.
 * @property { Boolean } hasPreview Returns whether a subscription preview exists.
 * @property { Object | null } getRecipient Returns the state of the subscription recipient.
 * @property { Boolean } hasRecipient Returns whether a subscription recipient exists.
 */
export default function SubscriptionProvider() {
  return readonly({
    loadConfig,
    updateProduct,
    updateCoupon,
    updateCustomer,
    updateMembership,
    activateProfile,
    setProfile,
    removeProfile,
    updateRecipient,
    purchase,
    loadUserSubscription,
    updateOptin,
    updateShipping,
    setShippingRequired,
    setAdditionalData,
    // General
    isLoading: computed(() => Boolean(state.isLoading)),
    isCreating: computed(() => Boolean(state.isCreating)),
    isUpdating: computed(() => Boolean(state.isUpdating)),
    // Product
    getProduct: computed(() => state.product),
    hasProduct: computed(() => Boolean(state.product)),
    // Profile
    getProfile: computed(() => state.profile),
    hasProfile: computed(() => Boolean(state.profile)),
    // Coupon
    getCoupon: computed(() => state.coupon),
    hasCoupon: computed(() => Boolean(state.coupon)),
    // Preview
    getPreview: computed(() => state.preview),
    hasPreview: computed(() => Boolean(state.preview)),
    // Recipient
    getRecipient: computed(() => state.recipient),
    hasRecipient: computed(() => Boolean(state.recipient)),
    // Subscription
    getSubscription: computed(() => state.subscription),
    hasSubscription: computed(() => Boolean(state.subscription)),
    // Payment Profile
    isCreatingProfile: computed(() => state.creatingProfile),
    setCreatingProfile: (creating) => state.creatingProfile = creating,
    isValidProfile: computed(() => state.validProfile),
    setValidProfile: (value) => state.validProfile = value,
    hasCompleteShipping: computed( () => {
      return state.shipping_address.shipping_address && state.shipping_address.shipping_city && state.shipping_address.shipping_state && state.shipping_address.shipping_zip && state.shipping_address.shipping_country;
    })
  });
}
